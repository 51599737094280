import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link as DoczLink, Props } from 'docz';
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, LeadParagraph, Paragraph, SubParagraph, Label, SubLabel, SmallText, StrongText, EmphasizedText, Link, PreformattedText, CodeText, UnorderedList, NumberedList, ListItem, Blockquote, BlockquoteFooter } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
  <Link as={DoczLink} to="/identitet/verktoykassen/typografi" mdxType="Link">
    Retningslinjer for bruk av typografi
  </Link>{' '}
  står beskrevet under Visuell identitet.
    </PageHeader>
    <p>{`Det er definert en typografisk skala, hvor vi bruker ulike fontstørrelser for å skape typografisk hierarki i teksten. Dette er den typografiske skalaen for løpende brødtekst og titler, navngitt etter “heading” nummerering i synkende skala der øverste nivå er H1, deretter H2, H3 osv.`}</p>
    <p>{`Tabellen viser hvilke størrelser og linjeavstander vi skal bruke når vi designer for web. Ved å kombinere ulike fontstørrelser på en god måte kan vi skape et tydelig hierarki og en struktur som gjør det enklere for leseren å forstå og navigere seg gjennom tekstene våre.`}</p>
    <p><strong parentName="p">{`Tekstavstand:`}</strong></p>
    <p>{`Tekstavstanden skal kunne overstyres for å gjøre teksten lettere å lese. Suksesskriterium for tekstavstand er følgende:`}</p>
    <ul>
      <li parentName="ul">{`Linjehøyde (linjeavstand) angis til minst 1,5 ganger skriftstørrelsen.`}</li>
      <li parentName="ul">{`Avstand etter avsnitt angis til minst 2 ganger skriftstørrelsen.`}</li>
      <li parentName="ul">{`Avstanden mellom bokstaver i blokker av tekst (sperring) angis til minst 0,12 ganger skriftstørrelsen.`}</li>
      <li parentName="ul">{`Avstanden mellom ord angis til minst 0,16 ganger skriftstørrelsen.`}</li>
    </ul>
    <Props of={Heading1} title="Props, alle typografikomponenter" mdxType="Props" />
    <h2 {...{
      "id": "overskrifter"
    }}>{`Overskrifter`}</h2>
    <p>{`Vi har egne komponenter for hver overskriftstype - en for hvert av de 6 nivåene vi støtter.`}</p>
    <Playground __position={2} __code={'<Heading1>Overskrift nivå 1</Heading1>\n<Heading2>Overskrift nivå 2</Heading2>\n<Heading3>Overskrift nivå 3</Heading3>\n<Heading4>Overskrift nivå 4</Heading4>\n<Heading5>Overskrift nivå 5</Heading5>\n<Heading6>Overskrift nivå 6</Heading6>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Heading1 mdxType="Heading1">Overskrift nivå 1</Heading1>
  <Heading2 mdxType="Heading2">Overskrift nivå 2</Heading2>
  <Heading3 mdxType="Heading3">Overskrift nivå 3</Heading3>
  <Heading4 mdxType="Heading4">Overskrift nivå 4</Heading4>
  <Heading5 mdxType="Heading5">Overskrift nivå 5</Heading5>
  <Heading6 mdxType="Heading6">Overskrift nivå 6</Heading6>
    </Playground>
    <h2 {...{
      "id": "ingress"
    }}>{`Ingress`}</h2>
    <p>{`Ingressen er et kort avsnitt som normalt kommer etter tittelen på en side.`}</p>
    <Playground __position={3} __code={'<LeadParagraph>Entur gjør det enklere å reise kollektivt</LeadParagraph>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LeadParagraph mdxType="LeadParagraph">Entur gjør det enklere å reise kollektivt</LeadParagraph>
    </Playground>
    <h2 {...{
      "id": "vanlig-tekst"
    }}>{`Vanlig tekst`}</h2>
    <p>{`Vi har tre nivåer med tekst - "paragraph", "sub paragraph" og "small text".`}</p>
    <Playground __position={4} __code={'<Paragraph>\n  Entur leverer tjenester som gjør det enkelt å reise på tvers av alle\n  kollektivselskaper i Norge.\n</Paragraph>\n<SubParagraph>\n  Entur leverer tjenester som gjør det enkelt å reise på tvers av alle\n  kollektivselskaper i Norge.\n</SubParagraph>\n<SmallText>\n  Entur leverer tjenester som gjør det enkelt å reise på tvers av alle\n  kollektivselskaper i Norge.\n</SmallText>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
    kollektivselskaper i Norge.
  </Paragraph>
  <SubParagraph mdxType="SubParagraph">
    Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
    kollektivselskaper i Norge.
  </SubParagraph>
  <SmallText mdxType="SmallText">
    Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
    kollektivselskaper i Norge.
  </SmallText>
    </Playground>
    <h2 {...{
      "id": "label-tekst"
    }}>{`Label-tekst`}</h2>
    <p>{`Vi har to nivåer med "label"-tekst - "label" og "sub label".`}</p>
    <Playground __position={5} __code={'<Label>\n  Entur leverer tjenester som gjør det enkelt å reise på tvers av alle\n  kollektivselskaper i Norge.\n</Label>\n<br />\n<SubLabel>\n  Entur leverer tjenester som gjør det enkelt å reise på tvers av alle\n  kollektivselskaper i Norge.\n</SubLabel>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Label mdxType="Label">
    Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
    kollektivselskaper i Norge.
  </Label>
  <br />
  <SubLabel mdxType="SubLabel">
    Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
    kollektivselskaper i Norge.
  </SubLabel>
    </Playground>
    <h2 {...{
      "id": "lister"
    }}>{`Lister`}</h2>
    <p>{`Du har to forskjellige listetyper - usortert og nummerert.`}</p>
    <Playground __position={6} __code={'<Paragraph>\n  Usorterte lister er fine for å summere opp en rekke punkter der rekkefølgen\n  ikke er så viktig\n</Paragraph>\n<UnorderedList>\n  <ListItem>Du kan bruke dem til å summere opp hva kunden har kjøpt</ListItem>\n  <ListItem>Du kan liste opp hovedtrekk i en kjøpskontrakt</ListItem>\n  <ListItem>\n    Du kan gjerne skrive lengre punkter om du vil, men det er ofte lettere å\n    lese korte og konsise punkter enn lange og detaljrike tekster.\n  </ListItem>\n</UnorderedList>\n<Paragraph>\n  Nummererte lister er flotte når du har en rekke punker du vil fremheve\n  rekkefølgen på.\n</Paragraph>\n<NumberedList>\n  <ListItem title=\"Først bør du huske på å bruke title-propen\">\n    Da får du en fin fet overskrift på punktet ditt\n  </ListItem>\n  <ListItem title=\"For det andre bør du ha gode overskrifter\">\n    Dette er forferdelige eksempler, så gjør en mye bedre jobb enn det vi har\n    gjort her.\n  </ListItem>\n</NumberedList>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Usorterte lister er fine for å summere opp en rekke punkter der rekkefølgen
    ikke er så viktig
  </Paragraph>
  <UnorderedList mdxType="UnorderedList">
    <ListItem mdxType="ListItem">Du kan bruke dem til å summere opp hva kunden har kjøpt</ListItem>
    <ListItem mdxType="ListItem">Du kan liste opp hovedtrekk i en kjøpskontrakt</ListItem>
    <ListItem mdxType="ListItem">
      Du kan gjerne skrive lengre punkter om du vil, men det er ofte lettere å
      lese korte og konsise punkter enn lange og detaljrike tekster.
    </ListItem>
  </UnorderedList>
  <Paragraph mdxType="Paragraph">
    Nummererte lister er flotte når du har en rekke punker du vil fremheve
    rekkefølgen på.
  </Paragraph>
  <NumberedList mdxType="NumberedList">
    <ListItem title="Først bør du huske på å bruke title-propen" mdxType="ListItem">
      Da får du en fin fet overskrift på punktet ditt
    </ListItem>
    <ListItem title="For det andre bør du ha gode overskrifter" mdxType="ListItem">
      Dette er forferdelige eksempler, så gjør en mye bedre jobb enn det vi har
      gjort her.
    </ListItem>
  </NumberedList>
    </Playground>
    <h2 {...{
      "id": "andre-typografiske-elementer"
    }}>{`Andre typografiske elementer`}</h2>
    <h3 {...{
      "id": "blockquote"
    }}>{`Blockquote`}</h3>
    <Playground __position={7} __code={'<Blockquote cite=\"\">\n  Entur gjør det enklere å reise kollektivt\n  <BlockquoteFooter>Ola Nordmann, Entur</BlockquoteFooter>\n</Blockquote>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Blockquote cite="" mdxType="Blockquote">
    Entur gjør det enklere å reise kollektivt
    <BlockquoteFooter mdxType="BlockquoteFooter">Ola Nordmann, Entur</BlockquoteFooter>
  </Blockquote>
    </Playground>
    <h3 {...{
      "id": "link"
    }}>{`Link`}</h3>
    <Playground __position={8} __code={'<Link href=\"https://entur.no/\">Reiseplanleggeren til Entur</Link>'} __scope={{
      props,
      DefaultLayout,
      DoczLink,
      Props,
      Heading1,
      Heading2,
      Heading3,
      Heading4,
      Heading5,
      Heading6,
      LeadParagraph,
      Paragraph,
      SubParagraph,
      Label,
      SubLabel,
      SmallText,
      StrongText,
      EmphasizedText,
      Link,
      PreformattedText,
      CodeText,
      UnorderedList,
      NumberedList,
      ListItem,
      Blockquote,
      BlockquoteFooter,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Link href="https://entur.no/" mdxType="Link">Reiseplanleggeren til Entur</Link>
    </Playground>
    <h2 {...{
      "id": "css-properties"
    }}>{`CSS properties`}</h2>
    <p>{`Vi eksponerer noen CSS-properties fra denne pakken, som kan brukes på tvers av andre komponenter. Disse er:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`--primary-background-color;
--primary-text-color;
--primary-label-color;
`}</code></pre>
    <p>{`De vil bli dokumentert bedre etterhvert, men vil være de aktuelle hovedfargene for gitt tema og modus.`}</p>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "heading1-6"
    }}>{`Heading1-6`}</h3>
    <ImportStatement imports="Heading1, Heading2, Heading3, Heading4, Heading5, Heading6" mdxType="ImportStatement" />
    <Props of={Heading1} mdxType="Props" />
    <h3 {...{
      "id": "leadparagraph"
    }}>{`LeadParagraph`}</h3>
    <ImportStatement imports="LeadParagraph" mdxType="ImportStatement" />
    <Props of={LeadParagraph} mdxType="Props" />
    <h3 {...{
      "id": "paragraph"
    }}>{`Paragraph`}</h3>
    <ImportStatement imports="Paragraph" mdxType="ImportStatement" />
    <Props of={Paragraph} mdxType="Props" />
    <h3 {...{
      "id": "subparagraph"
    }}>{`SubParagraph`}</h3>
    <ImportStatement imports="SubParagraph" mdxType="ImportStatement" />
    <Props of={SubParagraph} mdxType="Props" />
    <h3 {...{
      "id": "smalltext"
    }}>{`SmallText`}</h3>
    <ImportStatement imports="SmallText" mdxType="ImportStatement" />
    <Props of={SmallText} mdxType="Props" />
    <h3 {...{
      "id": "label"
    }}>{`Label`}</h3>
    <ImportStatement imports="Label" mdxType="ImportStatement" />
    <Props of={Label} mdxType="Props" />
    <h3 {...{
      "id": "sublabel"
    }}>{`SubLabel`}</h3>
    <ImportStatement imports="SubLabel" mdxType="ImportStatement" />
    <Props of={SubLabel} mdxType="Props" />
    <h3 {...{
      "id": "strongtext"
    }}>{`StrongText`}</h3>
    <ImportStatement imports="StrongText" mdxType="ImportStatement" />
    <Props of={StrongText} mdxType="Props" />
    <h3 {...{
      "id": "emphasizedtext"
    }}>{`EmphasizedText`}</h3>
    <ImportStatement imports="EmphasizedText" mdxType="ImportStatement" />
    <Props of={EmphasizedText} mdxType="Props" />
    <h3 {...{
      "id": "link-1"
    }}>{`Link`}</h3>
    <ImportStatement imports="Link" mdxType="ImportStatement" />
    <Props of={Link} mdxType="Props" />
    <h3 {...{
      "id": "preformattedtext"
    }}>{`PreformattedText`}</h3>
    <ImportStatement imports="PreformattedText" mdxType="ImportStatement" />
    <Props of={PreformattedText} mdxType="Props" />
    <h3 {...{
      "id": "codetext"
    }}>{`CodeText`}</h3>
    <ImportStatement imports="CodeText" mdxType="ImportStatement" />
    <Props of={CodeText} mdxType="Props" />
    <h3 {...{
      "id": "unorderedlist"
    }}>{`UnorderedList`}</h3>
    <ImportStatement imports="UnorderedList" mdxType="ImportStatement" />
    <Props of={UnorderedList} mdxType="Props" />
    <h3 {...{
      "id": "numberedlist"
    }}>{`NumberedList`}</h3>
    <ImportStatement imports="NumberedList" mdxType="ImportStatement" />
    <Props of={NumberedList} mdxType="Props" />
    <h3 {...{
      "id": "listitem"
    }}>{`ListItem`}</h3>
    <ImportStatement imports="ListItem" mdxType="ImportStatement" />
    <Props of={ListItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      